body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  .container {
    text-align: center;
    margin-top: 100px;
  }
  
  h1 {
    font-size: 72px;
    color: #e74c3c;
  }
  
  p {
    font-size: 24px;
    color: #666;
  }
  