
.info-box {
    position: absolute;
    top: -80px; 
    left: -100px; 
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999; 
  }